<template>
  <div class="col-12">
    <div
      class="w-100 dragscroll"
      :class="[noMinHeight ? 'aaa' : !fixHeight ? 'x-scroll' : 'fix-height']"
      ref="scroll"
      v-on:mousedown="mouseDown"
      v-on:mouseleave="mouseLeave"
      v-on:mouseup="mouseUp"
      v-on:mousemove="mouseMove"
    >
      <table class="table table-borderless protean-data prod-tab one-by-one">
        <thead>
          <tr>
            <td
              :class="{ 'text-center': !row.textLeft ?? true }"
              v-for="row in rows.filter((i) => i.show)"
              :key="row.key"
            >
              <span class="regular-12 text-black">{{ row.name }}</span>
            </td>
            <td style="width: 16%" class="text-end">
              <button
                class="btn btn-type-2 btn-small"
                v-on:click="addbtn"
                v-if="showAdd"
              >
                <span class="regular-12">{{
                  $t(`oneByOneTable.new`, { type: type })
                }}</span>
              </button>
            </td>
          </tr>
        </thead>
        <tbody
          v-if="itemsDate === undefined || itemsDate === null"
          class="product-list-table"
        >
          <tr v-for="i in 5" :key="i">
            <td
              v-for="row in rows.filter((i) => i.show)"
              :key="row.key"
              :class="{ 'text-center': !row.textLeft ?? true }"
              class="regular-12"
            >
              <Skeletor v-if="row.type === 'avatar'" circle size="44" />
              <Skeletor v-else-if="row.type === 'image'" pill height="44" />
              <Skeletor
                v-else-if="row.type === 'tag' || row?.skeletor === 'rectangle'"
                pill
                height="20"
              />
              <Skeletor v-else />
            </td>
          </tr>
        </tbody>
        <tbody v-else class="product-list-table">
          <tr
            class="item-line"
            v-for="(item, i) in itemsDate"
            :class="{
              'deleted-line': deletedLine(item),
              'empty-line': itemsDate[i].emptyLine,
            }"
            :key="i"
          >
            <td
              v-for="row in rows.filter((i) => i.show)"
              :key="row.key"
              :class="{
                'text-center': !row.textLeft ?? true,
                'text-nowrap':
                  row.type != 'string' || itemsDate[i][row.key]?.length < 60,
              }"
              class="regular-12"
            >
              <img
                :src="itemsDate[i][row.key]"
                v-if="
                  row.type === 'logo' &&
                  itemsDate[i][row.key] !== undefined &&
                  itemsDate[i][row.key] !== null &&
                  itemsDate[i][row.key].length > 2
                "
                class="logo-image"
                alt=""
              />
              <img
                :src="itemsDate[i][row.key]"
                v-else-if="
                  row.type === 'image' &&
                  itemsDate[i][row.key] !== undefined &&
                  itemsDate[i][row.key] !== null &&
                  itemsDate[i][row.key].length > 2
                "
                class="product-image"
                alt=""
              />
              <BaseIcon
                v-else-if="row.type === 'image'"
                name="no-image"
                class="product-image"
              />
              <EditOnClick
                v-else-if="row.component === undefined"
                v-model="itemsDate[i][row.key]"
                :type="row.type"
                :values="row?.values"
                :select-key="row?.selectKey"
                :label="row?.label"
                :name="row?.name"
                :editable="
                  (row?.editable ?? true) &&
                  (row?.disabled_row === undefined ||
                    row?.disabled_row === null ||
                    itemsDate[i][row?.disabled_row] === true ||
                    itemsDate[i][row?.disabled_row] === 1)
                "
                :postfix="row?.postfix"
                :prefix="row?.prefix"
                v-on:update="
                  (e) => updateVal(itemsDate[i], row.key, itemsDate[i][row.key])
                "
              />
              <component
                v-else
                :is="row.component"
                :id="row?.id"
                :row="row"
                :itemtype="type"
                :extra-value="extraValue"
                :extra-row="row.extra_row_key ? item[row.extra_row_key] : null"
                v-model="itemsDate[i][row.key]"
                v-on:update="
                  (e) => updateVal(itemsDate[i], row.key, itemsDate[i][row.key])
                "
              />
            </td>
            <td class="text-end pe-4">
              <button
                class="btn btn-link text-gray-dark"
                v-if="showChange"
                v-on:click="changeEvent(itemsDate[i])"
              >
                <BaseIcon name="change" />
              </button>
              <button
                class="btn btn-link text-gray-dark"
                v-if="showDel"
                v-on:click="deleteEvent(itemsDate[i])"
              >
                <BaseIcon name="trash" />
              </button>
              <button
                class="btn btn-link text-gray-dark"
                v-if="showEdit"
                v-on:click="edit(itemsDate[i].id)"
              >
                <BaseIcon name="edit" />
              </button>
              <button
                class="btn btn-secondary btn-sm"
                v-if="showTick"
                v-on:click="tickEvent(itemsDate[i])"
              >
                <BaseIcon name="tick-circle" />
              </button>
            </td>
          </tr>
          <tr v-if="emptyLine">
            <td
              v-for="row in rows.filter((i) => i.show)"
              :key="row.key"
              :class="{ 'text-center': !row.textLeft ?? true }"
              class="regular-12 text-nowrap"
            >
              <EditOnClick
                v-if="
                  row.component === undefined &&
                  row.type !== 'logo' &&
                  row.type !== 'image'
                "
                v-model="newItem[row.key]"
                :type="row.type"
                :values="row?.values"
                :select-key="row?.selectKey"
                :label="row?.label"
                :name="row?.name"
                :editable="row?.editable"
                v-on:update="(e) => addVal(newItem, row.key, newItem[row.key])"
              />
              <component
                v-else
                :is="row.component"
                :id="row?.id"
                :row="row"
                :itemtype="type"
                v-model="newItem[row.key]"
                :extra-value="extraValue"
                :extra-row="row.extra_row_key ? item[row.extra_row_key] : null"
                v-on:update="(e) => addVal(newItem, row.key, newItem[row.key])"
              />
            </td>
            <td class="text-end pe-4"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import EditOnClick from "../inputs/EditOnClick";
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));

export default {
  name: "OneByOneTable",
  components: { BaseIcon, EditOnClick },
  props: {
    rows: Array,
    items: Array,
    type: String,
    showAdd: {
      type: Boolean,
      default: true,
    },
    showChange: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    showTick: {
      type: Boolean,
      default: false,
    },
    showDel: {
      type: Boolean,
      default: true,
    },
    emptyLine: {
      type: Boolean,
      default: false,
    },
    fixHeight: {
      type: Boolean,
      default: false,
    },
    noMinHeight: {
      type: Boolean,
      default: false,
    },
    checkDeleteRow: {
      default() {
        return null;
      },
      type: String,
    },
    extraValue: {
      default() {
        return null;
      },
      type: String,
    },
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.itemsDate = this.items;
      },
    },
  },
  data() {
    return {
      itemsDate: this.items,
      newItem: {},
      stickyLeftVal: {},
      isDown: false,
      slider: {
        isDown: false,
        startX: null,
        scrollLeft: null,
      },
    };
  },
  computed: {
    itemLength: function () {
      return this.itemsDate.length;
    },
  },
  methods: {
    mouseDown(e) {
      this.slider.isDown = true;
      this.$refs.scroll.classList.add("active");
      this.slider.startX = e.pageX - this.$refs.scroll.offsetLeft;
      this.slider.scrollLeft = this.$refs.scroll.scrollLeft;
    },
    mouseLeave() {
      this.slider.isDown = false;
      this.$refs.scroll.classList.remove("active");
    },
    mouseUp() {
      this.slider.isDown = false;
      this.$refs.scroll.classList.remove("active");
    },
    mouseMove(e) {
      if (!this.slider.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.scroll.offsetLeft;
      const walk = (x - this.slider.startX) * 2;
      this.$refs.scroll.scrollLeft = this.slider.scrollLeft - walk;
    },
    stickyLeft() {
      let sum = 0;
      for (const row of this.rows) {
        if (!row.sticky) {
          continue;
        }

        let e = this.$refs["row_" + row.key];
        if (e !== undefined) {
          this.stickyLeftVal[row.key] = sum;
          sum += e[0]?.offsetWidth;
        } else {
          setTimeout(this.stickyLeft, 1000);
        }
      }
    },
    addbtn() {
      this.$emit("addbtn");
    },
    edit(id) {
      this.$emit("edit", id);
    },
    deleteEvent(id) {
      this.$emit("delete", id);
    },
    changeEvent(id) {
      this.$emit("change", id);
    },
    tickEvent(id) {
      this.$emit("tick", id);
    },
    updateVal(item, key, val) {
      this.$emit("update:field", item, key, val);
    },
    addVal(item, key, val) {
      item[key] = "";
      this.$emit("add:field", key, val);
    },
    deletedLine(item) {
      if (this.checkDeleteRow === null) {
        return false;
      }

      return !item[this.checkDeleteRow] || item[this.checkDeleteRow] === 0;
    },
  },
  created() {
    for (const key in this.itemsDate) {
      this.itemsDate[key].selected = this.selectAll;
    }
  },
  emits: [
    "update:rows",
    "update:items",
    "addbtn",
    "edit",
    "delete",
    "change",
    "update:field",
    "add:field",
  ],
};
</script>
